export type Commission = {
  name: string
  manager: string
  members?: string[]
  children?: Commission[]
  superName?: string
  info?: string
}

const data: Commission[] = [
  {
    name: "Arbitres",
    manager: "Nadège DELZONGLE",
    info: "PCRA",
  },
  {
    name: "Calendrier",
    manager: "Robert FAIVRE",
  },
  {
    name: "Développement et communication",
    manager: "Hervé PACIEL",
    members: [
      "Julien BLATECKY",
      "Alexandra PARIZEL",
      "Jocelyne PETIT",
      "Isabelle TRONQUOY",
    ],
  },
  {
    name: "Formation",
    manager: "Matthieu FRATH",
    members: ["Francis HUE"],
  },
  {
    name: "Structuration et labellisation",
    manager: "Gérard BRISSAUD",
    info: "1 référent par département",
  },
  {
    name: "Médicale",
    manager: "Cécile ADT",
  },
  {
    name: "Projets et conseils",
    manager: "Philippe DORMONT",
    members: ["Matthieu FRATH"],
  },
  {
    name: "Promotion Sport Féminin",
    manager: "Alexandra PARIZEL",
  },
  {
    name: "Sport/Santé",
    manager: "Roland LANGAS",
  },
  {
    name: "Promotion du tir à l'arc",
    manager: "Francis HUE",
    members: ["Julien BLATECKY"],
  },
  {
    name: "Commission sportive",
    manager: "Jean-Bernard DUVAL",
    superName: "Sous-commissions",
    children: [
      {
        name: "Adultes",
        manager: "Bruno DÉPART",
        info: "Salle, TAE, DR",
        members: ["Hervé PACIEL", "Jocelyne PETIT"],
      },
      {
        name: "Jeunes",
        manager: "Jean-Bernard DUVAL",
        info: "UNSS, FSU, Pôle régional",
        members: ["Bruno DÉPART", "Isabelle TRONQUOY"],
      },
      {
        name: "Parcours",
        manager: "Marie DORMONT",
        info: "Campagne, 3D, Nature",
        members: ["Francis HUE", "Patrick KIEFER"],
      },
      {
        name: "Para tir à l'arc",
        manager: "Jocelyne PETIT",
        info: "FFH et FFSA",
        members: ["Gérard BRISSAUD", "Philippe DORMONT"],
      },
      {
        name: "Traditions",
        manager: "Jacky SOUDANT",
        info: "Beursault, Bouquet Provincial",
        members: ["Jocelyne PETIT"],
      },
    ],
  },
]

export default data
